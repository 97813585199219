/* .main-container{
    display:flex;
  }
  .sidebar{
    background: #000;
    color: #fff;
    width: 200px;
    height: 100vh;
    transition: all 0.5s;
    overflow: scroll

  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  .top_section {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 20px 15px;
}
  .logo{
    font-size: 30px;
  }
  .bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
  }
  .link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
  }
  .link:hover{
    background:lightskyblue;
    color: #000;
    transition: all 0.5s;
  }
  .active{
    background: #f8f9fa;
    color: #000;
  }
  .icon, .link_text {
    font-size: 15px;
  }

  .sub-menu {
    font-size: 15px;
} */

/* .sidebar{
  background: #171f29;
  color: #fff;
  width: 200px;
  height: 100vh !important;
  height: auto ;
  transition: all 0.5s;
  overflow: scroll

} */

.sidebar {
  background: #171f29;
  color: #fff;
  width: 200px;
  height: 100vh;
  /* Use only one value, either 100vh or auto */
  transition: all 0.5s;
  overflow: scroll;
  /* You should end the declaration with a semicolon */
}

::-webkit-scrollbar {
  display: none;
}

.sidebar .logo {
  font-size: 25px;
  margin-bottom: 0;
}

.sidebar button {
  color: #ffffff;
}

.sidebar li {
  padding: 5% 0% 5% 18%;
  position: relative;
}

/* .accordion {
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: rgb(13 110 253 / 30%);
}*/

.accordion-item {
  --bs-accordion-active-color: #000000;
  --bs-accordion-active-bg: rgb(13 110 253 / 30%);
  color: var(--bs-accordion-color);
  background-color: #171f29;
  border: none;
}

.sidebar .dropdown-arrow {
  position: absolute;
  right: 5%;
}

img.bg-img {
  height: 37px;
  background-color: #ffffff;
}



.sidebar p {
  margin-bottom: 0px;
  color: #99a5b5;
  font-family: Helvetica;
  font-size: 15px;
}

.sidebar p:hover {
}

.sidebar .text-clr {
  color: #99a5b5;
  font-size: 15px;
}

.accordion-button {
  color: #ffffff;
  background-color: #171f29;
}

.sidebar .top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5%;
}

.sidebar .top_section .bars {
  margin-right: 10%;
}

.sidebar .accordion-button:not(.collapsed) {
  color: #ffffff;
  background: none;
}

.sidebar .accordion-button:hover {
  background: none;
  box-shadow: none;
}

.sidebar .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.sidebar .accordion-button:focus {
  box-shadow: none;
}

.sidebar .accordion-button::after {
  filter: brightness(3);
}

.sidebar .accordion-button:hover::after {
  filter: brightness(10);
}

.sidebar li.active p {
  color: #ffffff;
}

.sidebar .accordion-item.active .text-clr,
.sidebar .accordion-item.active .accordion-header p {
  color: #ffffff;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
  filter: invert(1);
}



.sidebar .accordion-item:hover .text-clr,
.sidebar .accordion-item:hover .accordion-header p {
  color: #ffffff;
}

.sidebar li:hover .rounded p {
  color: #ffffff;
}

.sidebar .Itlogtitle {
  color: #fff;
  margin: 0px 0px 0px 17px;
}
.main-dash {
  display: flex;
  padding-left: 20px;
}
a {
  color: transparent;
  text-decoration: underline;
}
a:hover {
  color:transparent;
}
ul.list-unstyled{
  margin-top: 6%;
}
@media (min-width:0px) and (max-width:768px) {
  .sidebar p {
    font-size: 12px;
  }

  .sidebar .text-clr {
    font-size: 12px;
  }

  .main-advancedashboard {
    width: 85%;
    height: 100vh;
    transition: all 0.5s;
    overflow: scroll;
    margin: 0px 0px 0px auto;
  }

  .sidebar {
    position: fixed;
    top: 0px;
    z-index: 9999;
  }

  .sidebar .Itlogtitle {
    color: #fff;
    margin: 0px 0px 0px 17px;
    font-size: 15px;
  }
}