.Login {
    background: url('../image/Login_bg.png');
    height: 100vh !important;
    overflow: hidden;
    background-position: center;
    width: 100%;
    background-size: cover;
}

.Login .login-card {
    width: 422px;
    height: 423px;
    background: #005F7A96 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translate(0%, -50%);
}

.Login .form-control {
    padding: .375rem .75rem;
    font-size: 12px;
    font-weight: bold;
    color: #707070;
    border: 0.5px solid #FE7600;
    border-radius: 5px;
    filter: opacity(0.5);
    height: 30px;
}

.Login .sign-in-button {
    width: 100%;
    height: 48px;
    color: #FFFFFF;
    background: #01759A 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font: normal normal normal 18px/24px Open Sans;
    margin-top: 14%;
}



.Login .text-holder-login {
    color: #707070;
}

.Login .text-holder-login h2 {
    font: normal normal bold 30px/41px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.Login .text-holder-login p {
    color: #707070;
    font-size: 14px;
    font-weight: 600;
}


.Login .input-group .input-group-text {
    background: transparent !important;
    font-size: 12px;
    font-weight: bold;
    color: #707070;
    filter: opacity(0.5);
    border-left: 0px;
    border-color: #FE7600;
    border-radius: 0px;
    height: 30px;
    border-radius: 5px;

}

.Login .form-control {
    padding: .375rem .75rem;
    font-size: 12px;
    font-weight: bold;
    border: 0.5px solid #FE7600;
    border-radius: 5px;
    border-right: none;
    filter: opacity(0.5);
    background: transparent;
    height: 30px;
}

.Login .loginForm_txt {
    color: #fff !important;
}

.Login .loginForm_txt_main {
    text-align: left;
    position: relative;
}

.Login .login_eye_icon {
    position: absolute;
    right: 0;
    top: 45px;
    color: #fff;
}

.Login .form-control-email {
    background: transparent !important;
    border: 0;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #fff !important;
    font-size: 14px;
    height: 38px;
    box-shadow: none;
}

/* Media  */
@media(min-width:0px) and (max-width:320px) {
    

    .Login .login-card {
        padding: 20%;
        width: 100%;
    }

    .Login .sign-in-button {
        width: 100%;
    }
    .Login .login-card {
        left: 0%;
    }
}

@media(min-width:321px) and (max-width:485px) {
   

    .Login .sign-in-button {
        width: 100%;
    }

    .Login .login-card {
        padding: 20%;
        width: 100%;
    }

    .Login .login-card {
        left: 0%;
    }
}

@media(min-width:486px) and (max-width:575px) {
  

    .Login .login-card {
        padding: 20%;
    }

    .Login .sign-in-button {
        width: 100%;
    }
}