.AdvanceDashboard .AdvanceDashboardTabs .tab-content {
  background: #f8f9fa;
}

.main-advancedashboard {
  width: 100%;
  height: 100vh;
  transition: all 0.5s;
  overflow: scroll;
}
.AdvanceDashboard .users {
  color: #99a5b5;
}

.AdvanceDashboard .AdvanceDashboardTabsContent .box .users {
  /* background: #ed1b24;
  min-width: 35px;
  height: 35px;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.AdvanceDashboard .react-datepicker-wrapper  input {
  border: none;
  font-size: 15px;
}

.AdvanceDashboard {
  height: 100vh;
  overflow: scroll;
}

.AdvanceDashboard .badhboard-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #80808054;
  margin: 0px 22px;
  border-right: 1px solid #ccc;
}

.AdvanceDashboard .badhboard-header .setting {
  position: absolute;
  right: 20px;
  display: flex;
}

.AdvanceDashboard .badhboard-header .setting .calender {
  margin-right: 50px;
}

.AdvanceDashboard .AdvanceDashboardTabs .nav {
  border-bottom: 1px solid #80808054;
  border-top: 1px solid #80808054;
}

.AdvanceDashboard .AdvanceDashboardTabs .nav .nav-link {
  border: none;
  color: slategray;
  font-size: 15px;
}

.AdvanceDashboard .AdvanceDashboardTabs .nav .nav-link.active {
  border: none;
  border-bottom-style: inset;
  border-color: #ed1b24;
  background: #ed1b2429;
  border-radius: 0px;
  color: #ed1b24;
  font-weight: 600;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent {
  padding: 2% 2%;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .box {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        box-shadow: 0 0 4px 0 #e8eef3;
        background: #dc35450f !important;
        position: relative;
        height: 100%;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .box
  .number-holder {
  display: flex;
}
.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .box
  .number-holder
  .number {
  margin-bottom: 0px;
  margin-right: 20px;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .box
  .number-holder
  .number
  p {
  font-size: 12px;
  margin-bottom: 0px;
}
.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .box
  .number-holder
  .number
  span {
  font-size: 15px;
  font-weight: 600;
  color: #ed1b24;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .box
  .text-holder
  .text {
  font-size: 15px;
  font-size: 15px;
  margin-bottom: 20px;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card {
  --bs-card-border-color: none;
  height: 500px;
  padding: 5%;
  box-shadow: 0 0 4px 0 #e8eef3;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-title
  h4 {
  font-size: 18px;
  font-weight: 600;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .to-do
  td {
  border-bottom: none;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .to-do {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .to-do
  .text-holder
  h5 {
  font-size: 13px;
  font-weight: 500;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .to-do
  .text-holder
  span {
  font-size: 13px;
  color: slategrey;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .to-do
  .img-holder {
  display: flex;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  tr:hover {
  background-color: none;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .to-do
  .date-holder
  p {
  font-size: 13px;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .to-do
  .title-holder
  p {
  font-size: 13px;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .to-do
  .title-holder
  svg {
  color: #f5c308;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .card-body-data
  .no-data
  p {
  font-size: 15px;
  color: lightgrey;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  .heading
  th {
  font-size: 13px;
  font-weight: 500;
  color: slategray;
}

.AdvanceDashboard
  .AdvanceDashboardTabs
  .tab-content
  .AdvanceDashboardTabsContent
  .card
  .card-body
  .table
  tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: none;
}




@media (min-width:0px) and (max-width:768px){
  .AdvanceDashboard .badhboard-header {
    /* display: flex; */
    align-items: center;
    border-bottom: 1px solid #80808054;
    margin: 0px 10px;
    border-right: 1px solid #ccc;
    /* min-width: 500px; */
    /* overflow-x: scroll !important; */
  }
  .AdvanceDashboard .nav-tabs {
    display: flex;
    flex-wrap: unset;
    width: 60vw;
    overflow-x: scroll;
  }
  .AdvanceDashboard .badhboard-header .setting .calender {
    margin-right: 15px;
  }
}