@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
body{
  font-family: "Poppins", "sans-serif" !important;
 
}

*{
  margin:0;
  padding:0;
  text-decoration: none;
}

/* main {
  width: 100%;
} */

